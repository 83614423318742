import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {getTheme, useAppVersion} from '@ozark/common';
import {Loading, NotAvailable} from '@ozark/common/components';
import {SnackbarProvider} from 'notistack';
import {useEffect} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store';
import Application from '../Application';
import {AppTransferredMessage} from '../AppTransferredMessage';
import Confirmation from '../Confirmation';
import ConfirmationForAgents from '../ConfirmationForAgents';
import {ExpiredLink} from '../ExpiredLink';
import {PlaidCallback} from '../PlaidCallback';
import {Portal} from '../Portal';
import {Resume} from '../Resume';
import {Transfer} from '../Transfer';
import Pixel from './Pixel';

const App = () => {
  const {group} = useStore();
  const {appVersion, refreshCacheAndReload, isLatestVersion} = useAppVersion();

  useEffect(() => {
    if (!isLatestVersion) {
      refreshCacheAndReload();
    }
  }, [isLatestVersion, refreshCacheAndReload]);

  if (!group.data) return <div />;

  const theme = getTheme(group.data);
  const query = new URLSearchParams(window.location.search);
  const allParams = {} as any;

  for (let value of query.keys() as unknown as any[]) {
    allParams[value] = query.get(value);
  }

  if (group.promised || appVersion.promised) return <Loading />;
  const useRedirect = group.data?.isActive === false;
  if (useRedirect) {
    return <NotAvailable />;
  }

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={4}>
            <Router>
              <Pixel />
              <Switch>
                <Redirect
                  exact
                  from="/"
                  to={{pathname: ROUTES.START, state: {queryParams: allParams}}}
                />
                <Route
                  exact
                  path={[
                    ROUTES.START,
                    ROUTES.BASICS,
                    ROUTES.BUSINESS,
                    ROUTES.SIGNER,
                    ROUTES.DEPOSITS,
                    ROUTES.PROGRAM,
                    ROUTES.PROGRAM_REVIEW,
                    ROUTES.REVIEW,
                    ROUTES.INSTANT_ID_Q_AND_A,
                  ]}
                  component={Application}
                />
                <Route exact path={ROUTES.APPLICATION_DETAILS} component={Application} />
                <Route exact path={ROUTES.CONFIRMATION} component={Confirmation} />
                <Route exact path={ROUTES.SUBMITTED} component={ConfirmationForAgents} />
                <Route exact path={ROUTES.TRANSFERRED} component={AppTransferredMessage} />
                <Route exact path={ROUTES.PORTAL} component={Portal} />
                <Route exact path={ROUTES.TRANSFER} component={Transfer} />
                <Route exact path={ROUTES.RESUME} component={Resume} />
                <Route
                  exact
                  path={[
                    ROUTES.PORTAL_BASICS,
                    ROUTES.PORTAL_BUSINESS,
                    ROUTES.PORTAL_SIGNER,
                    ROUTES.PORTAL_DEPOSITS,
                    ROUTES.PORTAL_PROGRAM,
                  ]}
                  component={Portal}
                />
                <Route path={ROUTES.PLAID_CALLBACK} component={PlaidCallback} />
                <Route path={ROUTES.NOT_AVAILABLE} component={NotAvailable} />
                <Route path={ROUTES.EXPIRED_LINK} component={ExpiredLink} />
              </Switch>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
