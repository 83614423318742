import {Box, Typography} from '@mui/material';
import Header from '../Header';

interface Props {
  text: string;
}

export const GroupPageWithText = ({text}: Props) => {
  return (
    <>
      <Header>
        <></>
      </Header>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          marginTop: '-5%',
        }}
      >
        <Typography variant="h2">{text}</Typography>
      </Box>
    </>
  );
};
